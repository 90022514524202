import React, { useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";
// import Custom Components
import PageHeader from "../components/common/page-header";
import Accordion from "../components/features/accordion/accordion";
import Card from "../components/features/accordion/card";
import Seo from "../components/common/SEO";
function FAQ({ pageContext }) {
  const { title, content, seoMetaData } = pageContext;
  const [resultTable, setResultTable] = React.useState([]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      let html = new DOMParser().parseFromString(content, "text/html")

      var myTab = html.getElementById("faq-table")

      //loop through rows
      if (myTab.rows && myTab.rows.length > 0) {
        let table = []
        for (let i = 1; i < myTab.rows.length; i++) {
          var objCells = myTab.rows.item(i).cells
          if (
            (objCells.item(0).innerText !== " " &&
              objCells.item(0).innerText !== "") ||
            (objCells.item(1).innerText !== " " &&
              objCells.item(1).innerText !== "")
          ) {
            table.push({
              q: objCells.item(0).innerText,
              a: objCells.item(1).innerText,
            })
          }
        }
        setResultTable(table)
      }
    }
  }, [content])

  return (
    <MainLayout>
      <Seo title={title} page={{ title, seoMetaData }} />
      <div className="main">
        <h1 className="d-none">FAQ Page</h1>
        <PageHeader title="F.A.Q" />
        <Breadcrumb title="FAQ" />
        <div className="page-content">
          <div className="container">
            <p>
              Below are answers to commonly asked questions. If you don't see
              what you're looking for, please contact us . Click on the question
              to see the answer.
            </p>
            {resultTable
              .filter((el) => el !== " ")
              .map((elm,index) => (
                <Accordion key={index} adClass="accordion-rounded">
                  <Card title={elm.q} adClass="card-box card-sm bg-light">
                    {elm.a}
                  </Card>
                </Accordion>
              ))}
          </div>
        </div>
        <div
          className="cta cta-display bg-image pt-4 pb-4"
          style={{
            backgroundImage: `url('/assets/images/backgrounds/cta/bg-7.jpg')`,
          }}
        ></div>
      </div>
    </MainLayout>
  );
}
export default React.memo(FAQ);
